import React, { useEffect, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeForm from '../components/forms/checkoutForm/StripeForm';
import { CheckoutForm } from '../components/forms/checkoutForm/CheckoutForm';
import Layout from '../layouts/Layout';
import { Column, Container, Row } from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/types';
import { EmailForm } from '../components/forms/EmailForm';
import { OrderLines } from '../components/order/lines/OrderLines';
import { CartModel, OrderModel, UserModel } from '@nimles/models';
import {
  createOrder,
  loadCurrentUser,
  loadOrders,
  State,
} from '@nimles/react-redux';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.
const promise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

export default function Checkout({ location }) {
  const dispatch = useDispatch();
  const { values: orders, loaded } = useSelector<RootState, State<OrderModel>>(
    ({ orders }) => orders
  );

  const accessToken = useSelector<RootState, string>(
    ({ auth }) => auth.accessToken
  );

  const cart = useSelector<RootState, CartModel>(({ carts }) => carts.selected);

  const user = useSelector<RootState, UserModel>(
    ({ currentUser }) => currentUser.user
  );

  useEffect(() => {
    if (accessToken && !user) {
      dispatch(loadCurrentUser());
    }
  }, [accessToken, user]);

  useEffect(() => {
    if (!loaded) {
      dispatch(loadOrders());
    } else if (cart && !orders?.some(({ head }) => head.cartId === cart.id)) {
      dispatch(
        createOrder({ head: { cartId: cart.id, buyer: { userId: user.id } } })
      );
    }
  }, [loaded, cart, orders, user]);

  const order = useMemo<OrderModel>(
    () => cart && orders?.find(({ head }) => head.cartId === cart.id),
    [cart, orders]
  );

  return (
    <Layout header location={location}>
      {order ? (
        <Container padding="200px 0 0">
          <Row justify="center">
            <Column>
              <CheckoutForm />
            </Column>
            <Column>
              <OrderLines order={order} />
            </Column>
          </Row>
          <Row justify="center">
            <Column>
              <Elements stripe={promise}>
                <StripeForm />
              </Elements>
            </Column>
          </Row>
        </Container>
      ) : (
        <Container padding="200px 0 0">
          <Row justify="center">
            <Column>
              <EmailForm />
            </Column>
          </Row>
        </Container>
      )}
    </Layout>
  );
}
