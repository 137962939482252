import {
  CartItemModel,
  OrderLineModel,
  OrderModel,
  ProductModel,
} from '@nimles/models';
import { loadPublicProduct } from '@nimles/react-redux';
import {
  Column,
  Image,
  List,
  ListItem,
  Row,
} from '@nimles/react-web-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/types';
import { updateItem } from '../../../services/cartService';
import { getImageUrl } from '../../../utils';
import { Price } from '../../price/Price';

export const OrderLines = ({ order }: { order: OrderModel }) => {
  const dispatch = useDispatch();
  const products = useSelector<RootState, ProductModel[]>(
    ({ publicProducts }) => publicProducts.values
  );

  return (
    <List>
      {order.lines.map((line) => {
        return (
          <ListItem>
            <div>
              <Row align="center" margin="0 -15px 10px">
                <Column xs={30}>
                  {line.imageIds?.length ? (
                    <Image src={getImageUrl(line.imageIds[0])} />
                  ) : null}
                </Column>
                <Column flex>
                  <div>
                    <strong>{line?.name}</strong>
                  </div>
                </Column>
              </Row>
              <Row alignItems="center">
                <Column xs={50} alignItems="flex-end">
                  <Price value={line.unitNetPrice * line.quantity} />
                </Column>
              </Row>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
