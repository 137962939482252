/**
 * Created by lilit on 2019-03-08.
 */

import React, { FC, useContext, useState } from 'react';
import { Formik, Form } from 'formik';
import { UserModel } from '@nimles/models/lib/identity';
import {
  Row,
  Column,
  Card,
  CardActions,
  CardAction,
} from '@nimles/react-web-components';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { InputField } from '@nimles/react-web-forms';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, signInUser } from '@nimles/react-redux';
import { RootState } from '../../redux/types';
import { createPassword } from '../../utils';
import { CardArea } from './FormCard';
import { FieldSet } from '../fields/FieldSet';
import { AddressModel, LocationModel } from '@nimles/models';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: 'AIzaSyD0JeObfwLX1ZeTp4bOyh0fLbPWM0YXVSw',
  version: 'weekly',
});

interface GeocodeResponse {
  location: LocationModel;
  address: AddressModel;
}

interface Props {
  onSubmitted?: (values: any) => void;
}

export const EmailForm: FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { language } = useContext(I18nextContext);

  const handleSubmit = async (
    values,
    { setSubmitting, setErrors, setStatus }
  ) => {
    try {
      const { username } = values;

      const password = values.password || createPassword();

      const userResponse: any = await dispatch(
        createUser({
          username,
          password,
        })
      );
      await dispatch(signInUser({ username, password }));

      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      console.error(error);
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };
  const values = {
    username: '',
  };

  return (
    <>
      <Formik
        initialValues={values}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        enableReinitialize
        render={({ values, handleSubmit, isSubmitting }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              name="simpleForm"
              className="quote-request-form"
            >
              <Card>
                <CardArea>
                  <FieldSet>
                    <Row>
                      <Column flex>
                        <InputField
                          required
                          name="username"
                          label={t('prop.email')}
                          placeholder={t('prop.email')}
                        />
                      </Column>
                    </Row>
                  </FieldSet>
                </CardArea>
                <CardActions>
                  <CardAction
                    primary
                    raised
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t(isSubmitting ? 'action.sending' : 'action.send')}
                  </CardAction>
                </CardActions>
              </Card>
            </Form>
          );
        }}
      />
    </>
  );
};
